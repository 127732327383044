<template>
  <div>
    <div class="px-5 pb-3">
      <b-row align-v="center" class="steps">
        <b-col md v-for="(step, index) in steps" :key="index" class="step d-flex my-1"
               :class="getStepStatus(step)">

          <div class="step-info d-flex justify-content-center align-items-center flex-fill">
            <div class="step-number d-flex justify-content-center align-items-center mr-2">
              <b-spinner variant="white"></b-spinner>
              {{ index + 1 }}
            </div>
            <div class="step-name">
              {{ step.action }}
              <span>{{ step.token }}</span>
            </div>
          </div>

          <div class="step-corner hidden-sm">
            <svg width="25" height="40" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0 H5 L 25 20 L 5 40 L 0 40 Z" class="stroke"></path>
              <path d="M0 1 H4.5 L 23.5 20 L 4.5 39 L 0 39 Z" class="fill"></path>
            </svg>
          </div>

        </b-col>
      </b-row>
    </div>
    <div class="card-footer px-5 py-3" :class="getActiveItemByStepId(stepperUuid).status">
      <b-form-row class="mb-3">
        <b-col class="step-result">
          <span v-if="getActiveItemByStepId(stepperUuid).status === 'success'">Transaction submitted</span>
          <span v-else-if="getActiveItemByStepId(stepperUuid).status === 'error'">Transaction failed</span>
          <span v-else>Transaction in progress</span>
        </b-col>
        <b-col class="col-sm-auto" v-if="getActiveItemByStepId(stepperUuid).txHash">
          <div class="d-flex justify-content-end align-items-baseline" v-on:click="openEtherscan()">
            <div class="caption mr-2 my-0" clickable="">
              View on etherscan
            </div>
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" clickable>
              <path
                  d="M5.28293 0.424316C5.5724 0.424316 5.78951 0.636459 5.78951 0.919315C5.78951 1.20217 5.5724 1.41431 5.28293 1.41431H1.95396C1.44738 1.41431 1.01316 1.8386 1.01316 2.3336V7.99072C1.01316 8.48572 1.44738 8.91 1.95396 8.91H7.74347C8.25005 8.91 8.68426 8.48572 8.68426 7.99072V5.65716C8.68426 5.3743 8.90137 5.16216 9.19085 5.16216C9.48032 5.16216 9.69743 5.3743 9.69743 5.65716V7.99072C9.69743 9.05143 8.829 9.9 7.74347 9.9H1.95396C0.868426 9.9 0 9.05143 0 7.99072V2.3336C0 1.27289 0.868426 0.424316 1.95396 0.424316H5.28293Z"
                  fill="#80808F"/>
              <path
                  d="M10.7832 0.212142C10.7832 0.141428 10.7832 0.141428 10.7832 0.212142C10.7832 0.141428 10.7832 0.141428 10.7832 0.212142C10.7108 0.070714 10.5661 0.070714 10.4214 0.070714L7.3095 0C7.16476 0 7.02002 0.0707141 6.94765 0.141428C6.87528 0.212142 6.80291 0.35357 6.80291 0.494998C6.80291 0.636427 6.87528 0.777855 6.94765 0.848569C7.02002 0.919283 7.16476 0.989997 7.3095 0.989997H9.11872L4.70422 5.16213C4.55948 5.30356 4.48711 5.44498 4.41474 5.58641C4.34237 5.79855 4.41474 5.93998 4.48711 6.08141C4.55948 6.15212 4.70422 6.22284 4.84896 6.22284C4.92132 6.22284 4.92132 6.22284 4.99369 6.22284C5.13843 6.15212 5.35554 6.08141 5.42791 5.93998L9.91478 1.69714V3.46499C9.91478 3.74784 10.2043 3.95999 10.4937 3.95999C10.7832 3.95999 11.0003 3.67713 11.0003 3.39428L10.9279 0.494998C10.9279 0.424284 10.8556 0.282856 10.7832 0.212142Z"
                  fill="#80808F"/>
            </svg>
          </div>
        </b-col>
      </b-form-row>
      <div class="step-result-message d-flex justify-content-start my-3">
        <span v-if="getActiveItemByStepId(stepperUuid).status === 'success'">Success!</span>
        <span v-else-if="getActiveItemByStepId(stepperUuid).status === 'error'" class="error-message">
          {{ getActiveItemByStepId(stepperUuid).errorMessage }}
        </span>
        <span v-else></span>
      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StepInformer",
  props: {
    steps: Array,
    stepperUuid: String
  },
  data: function () {
    return {
      currentStep: {
        index: 1,
        result: {
          title: 'Transaction submitted',
          message: 'Success!',
          etherscanURL: ''
        }
      },
    }
  },
  methods: {
    getStepStatus(step) {
      if(step.status){
        return step.status
      }
      return ''
    },
    openEtherscan(){
      const etherscanURL = this.getEtherscanTxLink(this.getActiveItemByStepId(this.stepperUuid).txHash)
      window.open(etherscanURL || 'https://etherscan.io/', '_blank');
    }
  },
  computed: {
    ...mapGetters(['getActiveItemByStepId', 'getEtherscanTxLink'])
  }
}
</script>

<style scoped lang="scss">

  .hidden-sm {
    @media screen and (max-width: $screen-sm-max) {
      display: none !important;
    }
  }

  .row.steps {
    margin-right: unset;
    margin-left: unset;
  }

  .step {
    height: 40px;
    box-sizing: border-box;
    background-color: $color-gray-100;
    border: 1px solid $color-gray-200;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto -10px;
    color: $color-gray-500;

    .step-corner {
      svg path.fill {
        fill: $color-gray-100;
      }

      svg path.stroke {
        fill: $color-gray-200;
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      border-radius: 5px;
      margin: unset;
    }
    @media screen and (min-width: $screen-sm-max + 1) {
      .step-corner {
        position: absolute;
        right: 0px;
        top: -1px;
        z-index: 999;
      }
      &:first-of-type {
        border-radius: 5px;
        margin-left: 0;
        padding-left: 10px;
      }
      &:last-of-type {
        margin-right: 20px;
        padding-right: 5px;

        .step-corner {
          right: -20px;
        }
      }

    }

    .step-number {
      height: 16px;
      width: 16px;
      position: relative;
      border-radius: 50%;
      font-weight: bold;
      font-size: 10px;
      line-height: 10px;
      border: 1px solid $gray-500;

      .spinner-border {
        position: absolute;
        width: 18px;
        height: 18px;
        display: none;
      }
    }

    .step-name {
      span {
        font-weight: 700
      }
    }
  }

  .step.active {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
    color: $white;

    .step-number {
      border-color: transparent;

      .spinner-border {
        display: block;
      }
    }

    .step-name {
      color: $white;
    }

    .step-corner {
      svg path {
        fill: $color-primary-light;
      }
    }

  }

  .step.success {
    background-color: $white;
    border: 1px solid $color-gray-200;
    color: $color-gray-800;

    .step-number {
      border-color: transparent;
      background-color: $color-primary-dark;
      color: $white;
    }

    .step-corner {
      svg path.fill {
        fill: $white;
      }

      svg path.stroke {
        fill: $color-gray-200;
      }
    }
  }

  .step.error {
    background-color: #fddcdf;
    border-color: #fddcdf;
    color: $color-danger;

    .step-number {
      border-color: transparent;
      background-color: $color-danger;
      color: $white;
    }

    .step-corner {
      svg path {
        fill: #fddcdf;
      }
    }
  }

  .card-footer {
    border-top: 1px solid $color-gray-300;
    text-align: unset;

    &.success {
      color: $color-success;
    }

    &.error {
      color: $color-danger;
    }

    .step-result {
      font-weight: 700;
    }
  }

  .step-result-message .error-message {
    width: 100%;
  }

</style>
